import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Input from 'components/base/inputs/base-input/Input';
import { phoneNumberTransform } from 'utils/fieldMaskAndTransforms';
import ReactSelect from 'components/base/selects/base-select/ReactSelect';
import { OptionPropType, OptionsPropType } from 'utils/propTypes';
import BasicFormWizardContext from 'hooks/contexts/BasicFormWizardContext';

const phoneProps = {
  className: 'input--number',
  type: 'tel',
  placeholder: 'XXX-XXX-XXXX',
  valueTransform: phoneNumberTransform,
};

export default function PhoneFieldRow(props) {
  const {
    options,
    inputName,
    selectName,
    inputValue,
    selectValue,
    onEnter,
    ...rest
  } = props;

  const {
    formConfigs: { hasDynamicDisclaimer },
    formStatus: { isProcessing },
  } = useContext(BasicFormWizardContext);

  const handleEnter = () => {
    if (hasDynamicDisclaimer && isProcessing) {
      return;
    }

    if (onEnter) {
      onEnter();
    }
  };

  return (
    <div className="phoneInput__fields">
      <div className="phoneInput__field phoneInput__field--select">
        <ReactSelect
          {...rest}
          name={selectName}
          options={options}
          value={selectValue}
          placeholder="Select"
        />
      </div>
      <div className="phoneInput__field phoneInput__field--input">
        <Input
          {...rest}
          {...phoneProps}
          name={inputName}
          value={inputValue}
          onEnter={handleEnter}
        />
      </div>
    </div>
  );
}

PhoneFieldRow.propTypes = {
  selectValue: OptionPropType,
  inputValue: PropTypes.string,
  selectName: PropTypes.string,
  inputName: PropTypes.string,
  options: OptionsPropType,
};
